import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PlusIcon, PencilIcon, TrashIcon, RefreshCwIcon, ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';
import { CheckedState } from '@radix-ui/react-checkbox';
import { cn } from '@shadcn/utils';
// TODO: consider just using either AlertDialog or Dialog
// Alert is simpler to use but doesn't close as "easily"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableHeader,
  ScrollArea,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@shadcn/ui';
import projectServices, { ProjectInfoData } from 'app/services/project-services';
import { ProjectModal } from './ProjectModal';
import { formatSquareFeet } from 'app/utils/formatters';
import syncServices from 'app/services/sync-services';

const getPropertyColors = (type: string) => {
  const t = type.toLowerCase();
  return {
    'bg-chart-1 text-primary-foreground': t === 'residential',
    'bg-chart-2 text-primary-foreground': t === 'commercial',
    'bg-chart-3 text-muted-foreground': t === 'industrial',
  };
};

type SortField = 'name' | 'createdAt' | 'propertyType' | 'squareFeet';
type SortOrder = 'asc' | 'desc';

export const Project = () => {
  const [projects, setProjects] = useState<ProjectInfoData[]>([]);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState({});
  const [showDeleteFeedbackType, setShowDeleteFeedbackType] = useState<'success' | 'error' | null>(null);
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const handleNewProject = () => {
    setShowNewProjectModal(true);
  };

  const handleModalOpenChange = (newOpen: boolean) => {
    setShowNewProjectModal(newOpen);
    setRefreshProjects(!newOpen);
  };

  const getProjectPath = (project: { id: string }) => {
    return `/dashboard/project/${project.id}/building-info`;
  };

  const handleCheckboxChange = (_: CheckedState, projectId: string) => {
    setSelectedProjects((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  const handleDeleteConfirm = async () => {
    const projectsToDelete = Object.keys(selectedProjects).filter((id) => selectedProjects[id]);
    try {
      await Promise.all(projectsToDelete.map((id) => projectServices.delete(id)));
      setRefreshProjects((prev) => !prev);
      setSelectedProjects({});
      setShowDeleteFeedbackType('success');
    } catch (error) {
      setShowDeleteFeedbackType('error');
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedProjects = [...projects].sort((a, b) => {
    let comparison = 0;
    switch (sortField) {
      case 'name':
        comparison = a.attributes.name.localeCompare(b.attributes.name);
        break;
      case 'createdAt':
        comparison = new Date(a.attributes.createdAt).getTime() - new Date(b.attributes.createdAt).getTime();
        break;
      case 'propertyType':
        comparison = a.attributes.propertyType.localeCompare(b.attributes.propertyType);
        break;
      case 'squareFeet':
        comparison = (a.attributes.squareFeet || 0) - (b.attributes.squareFeet || 0);
        break;
    }
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  const SortButton = ({ field, label }: { field: SortField; label: string }) => (
    <Button variant="ghost" onClick={() => handleSort(field)} className="flex items-center gap-1 hover:bg-transparent">
      {label}
      {sortField !== field && <ArrowUpDown size={16} className="opacity-50" />}
      {sortField === field && sortOrder === 'asc' && <ArrowUp size={16} />}
      {sortField === field && sortOrder === 'desc' && <ArrowDown size={16} />}
    </Button>
  );

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const projectsArray = await projectServices.get();
        if (isMounted) {
          setProjects(projectsArray);
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [refreshProjects]);

  const isAnyProjectSelected = Object.values(selectedProjects).some(Boolean);

  return (
    <div className="flex flex-col overflow-hidden px-4 pt-6">
      <div className="mb-6 flex items-center justify-between">
        <h4 className="bold text-gray-900">Projects</h4>
        <div className="flex flex-row gap-4">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                className="flex flex-row gap-2 uppercase disabled:opacity-0"
                variant="destructive"
                disabled={!isAnyProjectSelected}
              >
                <TrashIcon /> Delete Selected
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete selected projects?</AlertDialogTitle>
                <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction variant="destructive" onClick={handleDeleteConfirm}>
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <Button className="flex flex-row gap-2 uppercase" onClick={handleNewProject}>
            <PlusIcon /> New Project
          </Button>
        </div>
      </div>
      <ScrollArea orientation="vertical" className="grow">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <Checkbox
                  color="primary"
                  checked={
                    Object.values(selectedProjects).some(Boolean) && !Object.values(selectedProjects).every(Boolean)
                      ? 'indeterminate'
                      : Object.values(selectedProjects).every(Boolean)
                  }
                  onClick={() => {
                    const newSelectedProjects = {};
                    projects.forEach((project) => {
                      newSelectedProjects[project.id] = !Object.values(selectedProjects).every(Boolean);
                    });
                    setSelectedProjects(newSelectedProjects);
                  }}
                />
              </TableHead>
              <TableHead>
                <SortButton field="name" label="Project Name" />
              </TableHead>
              <TableHead>
                <SortButton field="createdAt" label="Created At" />
              </TableHead>
              <TableHead>
                <SortButton field="propertyType" label="Property Type" />
              </TableHead>
              <TableHead>
                <SortButton field="squareFeet" label="Square Feet" />
              </TableHead>
              <TableHead>
                <p className="bold text-gray-700">Energy Star ID</p>
              </TableHead>
              <TableHead>
                <p className="bold text-gray-700">Actions</p>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedProjects.map((project) => (
              <TableRow key={project.id}>
                <TableCell>
                  <Checkbox
                    checked={!!selectedProjects[project.id]}
                    onCheckedChange={(c) => handleCheckboxChange(c, project.id)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <RouterLink
                    className="text-gray-900 no-underline transition-colors hover:text-primary hover:no-underline"
                    to={getProjectPath(project)}
                  >
                    {project.attributes.name}
                  </RouterLink>
                </TableCell>
                <TableCell className="text-gray-700">
                  {new Date(project.attributes.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <Badge
                    className={cn(
                      'bold pointer-events-none bg-[#9e9e9e] text-primary-foreground',
                      getPropertyColors(project.attributes.propertyType)
                    )}
                  >
                    {project.attributes.propertyType}
                  </Badge>
                </TableCell>
                <TableCell className="text-gray-700">{formatSquareFeet(project.attributes.squareFeet)}</TableCell>
                <TableCell className="text-gray-700">{project.attributes.energyStarId || '-'}</TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Button size="icon" variant="ghost" className="text-primary" asChild>
                      <RouterLink to={getProjectPath(project)} aria-label="Edit project">
                        <PencilIcon />
                      </RouterLink>
                    </Button>
                    <Button
                      size="icon"
                      variant="ghost"
                      className="text-primary"
                      onClick={async () => {
                        try {
                          await syncServices.syncWithEnergyStar(project.id, project.attributes.energyStarId);
                          setRefreshProjects((prev) => !prev);
                        } catch (error) {
                          console.error('Failed to sync with Energy Star:', error);
                        }
                      }}
                      aria-label="Sync with Energy Star"
                    >
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <RefreshCwIcon />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Sync with Energy Star</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
      <ProjectModal open={showNewProjectModal} setOpen={handleModalOpenChange} />

      <Dialog open={showDeleteFeedbackType !== null}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{showDeleteFeedbackType === 'success' ? 'Success' : 'Error'}</DialogTitle>
            <DialogDescription>
              {showDeleteFeedbackType === 'success'
                ? 'Project(s) deleted successfully.'
                : 'Failed to delete projects. Please try again'}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={() => setShowDeleteFeedbackType(null)}>Continue</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
