import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui';
import { SunIcon, CloudRainIcon, ChevronUpIcon, ChevronDownIcon } from 'lucide-react';
import { WeatherPicker } from './WeatherPicker';
import { WeatherProps } from 'app/types/visualizations';
import { Documents } from 'app/types/visualizations';
import projectServices from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';

interface WeatherSectionProps {
  projectId: string;
  documents: Documents;
  meterReadings: Documents;
  weather: WeatherProps;
  isWeatherOpen: boolean;
  isWeatherLoading: boolean;
  isEditing: boolean;
  zipCode: string;
  startDate: string;
  endDate: string;
  onWeatherChange: (weather: WeatherProps) => void;
  onWeatherOpenChange: (isOpen: boolean) => void;
  onEditingChange: (isEditing: boolean) => void;
  onZipCodeChange: (zipCode: string) => void;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onSnackbar: (message: string, variant: 'default' | 'destructive' | 'positive' | null | undefined) => void;
}

export const WeatherSection = ({
  projectId,
  documents,
  meterReadings,
  weather,
  isWeatherOpen,
  isWeatherLoading,
  isEditing,
  zipCode,
  startDate,
  endDate,
  onWeatherChange,
  onWeatherOpenChange,
  onEditingChange,
  onZipCodeChange,
  onStartDateChange,
  onEndDateChange,
  onSnackbar,
}: WeatherSectionProps) => {
  const hasDocuments = documents ? Object.values(documents).some((entry) => entry?.billingPeriods?.length > 0) : false;
  const hasMeterReadings = meterReadings
    ? Object.values(meterReadings).some((entry) => entry?.billingPeriods?.length > 0)
    : false;

  const toggleWeather = () => {
    onWeatherOpenChange(!isWeatherOpen);
    onEditingChange(false);
  };

  const handleAddWeather = async () => {
    try {
      const params = {
        id: projectId,
        data: { zipCode, startDate, endDate },
      };

      const savedProject = await projectServices.addWeather(params);
      if (savedProject) {
        onSnackbar('Weather data added successfully.', 'positive');
        onWeatherChange(savedProject.weather);
        onEditingChange(false);
        onWeatherOpenChange(false);
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to add weather data.', 'destructive');
    }
  };

  const buttonClass = hasDocuments || hasMeterReadings ? '' : 'opacity-50';
  const tooltipContent =
    hasDocuments || hasMeterReadings ? 'Add or edit weather data' : 'Add documents to enable weather data';

  return (
    <>
      <div className="flex items-center justify-between border-b">
        <div className="mb-8 flex h-10 h-12 items-center justify-end rounded-lg border border-border/20 bg-muted p-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.4),0_4px_6px_-1px_rgba(0,0,0,0.3)] after:absolute after:bottom-[-8px] after:left-0 after:h-[8px] after:w-full after:bg-gradient-to-b after:from-black/20 after:to-transparent">
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="inline-block">
                <Button onClick={toggleWeather} disabled={!(hasDocuments || hasMeterReadings)} className={buttonClass}>
                  {isWeatherOpen ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
                  {weather && Object.keys(weather).length > 0 ? (
                    <SunIcon className="ml-2 h-6 w-6 text-yellow-400" />
                  ) : (
                    <CloudRainIcon className="ml-2 h-6 w-6" />
                  )}
                </Button>
              </span>
            </TooltipTrigger>
            <TooltipContent>{tooltipContent}</TooltipContent>
          </Tooltip>
        </div>
      </div>

      {isWeatherOpen &&
        ((documents && Object.keys(documents).length > 0) ||
          (meterReadings && Object.keys(meterReadings).length > 0)) && (
          <div className="border-b px-2 py-4">
            <WeatherPicker
              zipCode={zipCode}
              startDate={startDate}
              endDate={endDate}
              isEditing={isEditing}
              isWeatherLoading={isWeatherLoading}
              hasWeatherData={weather && Object.keys(weather).length > 0}
              onZipCodeChange={onZipCodeChange}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
              onSubmit={handleAddWeather}
              onStartEditing={() => onEditingChange(true)}
              onCancelEditing={() => onEditingChange(false)}
            />
          </div>
        )}
    </>
  );
};
