import { forwardRef, useState } from 'react';
import { cn } from '@shadcn/utils';
import { Building2, Loader2, AlertTriangle } from 'lucide-react';
import { PenaltyData } from 'app/types/penalty-data';
import { DataSource, Documents } from 'app/types/visualizations';
import YearlyEnergySummary from './YearlyEnergySummary';
import EnergyUsageBreakdown from './EnergyUsageBreakdown';
import EmissionsTrendAnalysis from './EmissionsTrendAnalysis';
import type { PropertyMetrics } from 'app/services/energy-star-services';
import { EmissionsSummary } from 'app/components/emissions/EmissionsSummary';
import { ProjectInfoData } from 'app/services/project-services';
import { Theme } from 'app/types/theme';
import YearlyUsageTable from './YearlyUsageTable';
import YearlyUsageByServiceTable from './YearlyUsageByServiceTable';

interface EstimatedPenaltyProps {
  dataSource: DataSource;
  energyStarScoreByDateRange?: { [dateRange: string]: PropertyMetrics };
  formattedMeterReadings: Documents;
  formattedDocuments: Documents;
  isLoading: boolean;
  project: ProjectInfoData;
  theme: Theme;
}

const UsageSummaryTables = forwardRef<HTMLDivElement, EstimatedPenaltyProps>(
  (
    { dataSource, energyStarScoreByDateRange, formattedMeterReadings, formattedDocuments, isLoading, project, theme },
    ref
  ) => {
    const [error, setError] = useState<string | null>(null);

    if (isLoading) {
      return (
        <div className="flex h-[100%] items-center justify-center py-8">
          <Loader2 className={cn('h-20 w-20 animate-spin text-primary')} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex h-[100%] items-center justify-center py-8">
          <div className="rounded-xl bg-gray-50 p-8 text-center shadow-lg">
            <AlertTriangle className="mx-auto mb-4 h-12 w-12 text-red-500" />
            <div className="text-lg font-semibold text-gray-900">Error: {error}</div>
          </div>
        </div>
      );
    }

    if (
      (dataSource === 'energy-star' && !formattedMeterReadings) ||
      (dataSource === 'utility-bills' && !formattedDocuments)
    ) {
      return (
        <div className="flex h-[100%] items-center justify-center p-8">
          <div className="max-w-2xl rounded-xl bg-gray-50 p-8 text-center shadow-lg">
            <Building2 className="mx-auto mb-4 h-12 w-12 text-blue-600" />
            <h3 className="mb-2 text-xl font-semibold text-gray-900">No Estimations Available</h3>
            <p className="text-sm text-gray-600">
              Upload {dataSource === 'energy-star' ? 'meter readings' : 'utility bills'} for this project to start
              analyzing your energy usage, carbon emissions, and estimated penalties.
            </p>
          </div>
        </div>
      );
    }

    const data = dataSource === 'energy-star' ? formattedMeterReadings : formattedDocuments;
    const yearlyData = data?.yearlyData;

    const yearsData = Object.values(yearlyData);

    // Sort years in descending order (most recent first)
    const sortedYears = Object.entries(data?.yearlyData ?? {})
      .filter(([_, yearData]) => !(yearData.eui.siteEUI === 0 && yearData.eui.sourceEUI === 0))
      .sort(([_, a], [__, b]) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
      .map(([_, yearData]) => yearData);

    // Determine which services have data
    const activeServices = {
      electric: sortedYears.some((year) => year.energyUsageTotalsByService?.electric?.totalUsage),
      gas: sortedYears.some((year) => year.energyUsageTotalsByService?.gas?.totalUsage),
      steam: sortedYears.some((year) => year.energyUsageTotalsByService?.steam?.totalUsage),
      oil: sortedYears.some((year) => year.energyUsageTotalsByService?.oil?.totalUsage),
      water: sortedYears.some((year) => year.energyUsageTotalsByService?.water?.totalUsage),
    };

    return (
      <div ref={ref} className="space-y-4">
        <div className="flex gap-4">
          <div className="w-1/2">
            <EmissionsSummary
              dataSource={dataSource}
              utilityBills={formattedDocuments}
              meterReadings={formattedMeterReadings}
              project={project}
              theme={theme}
            />
          </div>
          <div className="w-1/2">
            <YearlyUsageTable sortedYears={sortedYears} energyStarScoreByDateRange={energyStarScoreByDateRange} />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <YearlyUsageByServiceTable sortedYears={sortedYears} activeServices={activeServices} />
          </div>
          <div className="w-1/2">
            <EmissionsTrendAnalysis yearsData={yearsData} />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <EnergyUsageBreakdown yearsData={yearsData} />
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    );
  }
);

export default UsageSummaryTables;
