import { cn } from '@shadcn/utils';
import { VariantProps } from 'class-variance-authority';
import { X, RefreshCw } from 'lucide-react';
import { forwardRef, useEffect, useState, useRef } from 'react';
import {
  ScrollArea,
  toastVariants,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Label,
  Card,
  Badge,
  Button,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Checkbox,
} from '@shadcn/ui';
import { ReportPreview } from './ReportPreview';
import type { ProjectDataReturn } from 'app/hooks/useProjectData';
import { DataSource } from 'app/types/visualizations';
import type { EnergyYearData } from 'app/types/visualizations';

interface ReportsProps {
  projectId: string;
  projectData: ProjectDataReturn;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const ReportBuilder = forwardRef<HTMLDivElement, ReportsProps>(({ projectId, projectData, onSnackbar }, ref) => {
  const [yearsData, setYearsData] = useState<EnergyYearData[]>([]);
  const [selectedDateRanges, setSelectedDateRanges] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<DataSource>('utility-bills');
  const [refreshKey, setRefreshKey] = useState(0);
  const [showRefreshConfirm, setShowRefreshConfirm] = useState(false);
  const [reportName, setReportName] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [generateWithAI, setGenerateWithAI] = useState(false);
  const reportBuilderRef = useRef<any>(null);

  const documents = projectData.documents;
  const meterReadings = projectData.meterReadings;

  // Update date range selection whenever data source changes
  useEffect(() => {
    const currentData = dataSource === 'utility-bills' ? documents : meterReadings;
    if (currentData?.yearlyData) {
      const yearsData = Object.entries(currentData.yearlyData).map(([year, data]) => ({
        startDate: data.startDate,
        endDate: data.endDate,
        billingDays: data.billingDays || 0,
        monthsCovered: data.monthsCovered,
        monthsCoveredByService: data.monthsCoveredByService || {},
        energyUsageTotalsByService: data.energyUsageTotalsByService || {},
        energySums: data.energySums || {},
        energySumsKBTU: data.energySumsKBTU || {},
        eui: data.eui,
        actualEmissions: data.actualEmissions,
        threshold: data.threshold,
        estimatedAnnualPenalty: data.estimatedAnnualPenalty || 0,
      }));
      setYearsData(yearsData);
    }
  }, [dataSource, documents, meterReadings]);

  const availableYears =
    yearsData
      ?.map((yearData) => ({
        year: new Date(yearData.startDate).getFullYear(),
        dateRange: `${new Date(yearData.startDate).toLocaleDateString()} - ${new Date(yearData.endDate).toLocaleDateString()}`,
      }))
      .sort((a, b) => b.year - a.year) || [];

  const handleDateRangeSelect = (dateRange: string) => {
    setSelectedDateRanges((prev) => {
      if (prev.includes(dateRange)) {
        return prev.filter((dr) => dr !== dateRange);
      }
      return [...prev, dateRange];
    });
  };

  const removeDateRange = (dateRangeToRemove: string) => {
    setSelectedDateRanges((prev) => prev.filter((dateRange) => dateRange !== dateRangeToRemove));
  };

  // if (isLoading) {
  //   return (
  //     <div className="flex h-full grow items-center justify-center">
  //       <LoaderCircleIcon className="animate-spin" />
  //     </div>
  //   );
  // }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="relative flex grow flex-col">
        <Card className="mb-6 rounded-lg border border-border bg-card p-6 shadow-sm">
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-8">
              <div className="flex items-center gap-3">
                <Label className="text-sm font-medium">Report Name:</Label>
                <Input
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  placeholder="Enter report name"
                  className="w-[200px]"
                />
              </div>

              <div className="flex items-center gap-3">
                <Label className="text-sm font-medium">Data Source:</Label>
                <Select
                  value={dataSource}
                  onValueChange={(value) => {
                    setDataSource(value as DataSource);
                    setSelectedDateRanges([]);
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select data source" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="utility-bills">Utility Bills</SelectItem>
                    <SelectItem value="energy-star">Meter Readings</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="flex items-center gap-3">
                <Label className="text-sm font-medium">Date Range:</Label>
                <Select value="" onValueChange={handleDateRangeSelect}>
                  <SelectTrigger className="w-[300px]">
                    <SelectValue placeholder="Select 12 month date range" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableYears
                      .filter((yearData) => !selectedDateRanges.includes(yearData.dateRange))
                      .map((yearData, index) => (
                        <SelectItem key={yearData.year - index} value={yearData.dateRange}>
                          {yearData.dateRange}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="flex items-center gap-2">
                <Checkbox
                  id="generateWithAI"
                  checked={generateWithAI}
                  onCheckedChange={(checked) => setGenerateWithAI(checked as boolean)}
                  disabled={!reportName.trim() || selectedDateRanges.length === 0}
                />
                <Label
                  htmlFor="generateWithAI"
                  className={cn(
                    'text-sm font-medium',
                    (!reportName.trim() || selectedDateRanges.length === 0) && 'text-muted-foreground'
                  )}
                >
                  Generate with AI
                </Label>
              </div>

              <div className="ml-auto flex items-center gap-3">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <Button
                          onClick={() => {
                            if (showPreview) {
                              setShowRefreshConfirm(true);
                            } else {
                              setRefreshKey((prev) => prev + 1);
                              setShowPreview(true);
                            }
                          }}
                          className="flex items-center gap-2"
                          disabled={!reportName.trim() || selectedDateRanges.length === 0}
                        >
                          <RefreshCw className="h-4 w-4" />
                          Generate Report Preview
                        </Button>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {!reportName.trim()
                        ? 'Please enter a report name to generate preview'
                        : selectedDateRanges.length === 0
                          ? 'Please select at least one date range to generate preview'
                          : 'Generate a new report preview'}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>

            {selectedDateRanges.length > 0 && (
              <div className="flex flex-wrap items-center gap-2">
                <Label className="text-sm font-medium">Selected Date Ranges:</Label>
                {selectedDateRanges.map((dateRange) => {
                  const yearData = yearsData.find(
                    (year) =>
                      `${new Date(year.startDate).toLocaleDateString()} - ${new Date(year.endDate).toLocaleDateString()}` ===
                      dateRange
                  );
                  return (
                    <div key={dateRange} className="flex flex-col gap-1">
                      <Badge variant="secondary" className="flex items-center gap-1">
                        {dateRange}
                        <X
                          className="h-3 w-3 cursor-pointer hover:text-destructive"
                          onClick={() => removeDateRange(dateRange)}
                        />
                      </Badge>
                      {yearData && (
                        <div className="text-xs text-muted-foreground">
                          {Object.entries(yearData.monthsCoveredByService || {}).map(([service, months]) => (
                            <div key={service}>
                              {service.charAt(0).toUpperCase() + service.slice(1)}: {months} months
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Card>

        <ScrollArea orientation="vertical" className={cn('grow')}>
          <div className="h-[calc(100vh-280px)] p-4">
            {showPreview && (
              <ReportPreview
                ref={reportBuilderRef}
                key={refreshKey}
                projectId={projectId}
                onClose={() => {}}
                selectedDateRanges={selectedDateRanges}
                reportName={reportName}
                dataSource={dataSource}
                generateWithAI={generateWithAI}
              />
            )}
          </div>
        </ScrollArea>
      </div>

      <AlertDialog open={showRefreshConfirm} onOpenChange={setShowRefreshConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Refresh Report Preview</AlertDialogTitle>
            <AlertDialogDescription>
              Generating Report Preview will lose any edits to the current preview. Do you wish to proceed?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setRefreshKey((prev) => prev + 1);
                setShowPreview(true);
                setShowRefreshConfirm(false);
              }}
            >
              Proceed
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
});
