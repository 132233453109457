import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatNumber } from 'app/utils/formatters';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { BarChartIcon } from 'lucide-react';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
} from 'app/components/charts/shared/chartProps';

interface EnergyUsageBreakdownProps {
  yearsData?: any[];
}

const EnergyUsageBreakdown = ({ yearsData }: EnergyUsageBreakdownProps) => {
  if (!yearsData?.length) {
    return (
      <NoDataFound
        message="No energy usage breakdown data available"
        submessage="There is no energy usage data to display for this time period"
        icon={<BarChartIcon className="h-8 w-8 text-gray-400" />}
      />
    );
  }

  return (
    <CollapsibleChart title="Energy Usage Breakdown">
      <div className="h-[500px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={yearsData} margin={{ top: 20, right: 80, left: 80, bottom: 100 }}>
            <CartesianGrid {...sharedCartesianGridProps} />
            <XAxis
              {...sharedXAxisProps}
              dataKey="year"
              label={{
                position: 'bottom',
                offset: 10,
              }}
            />
            <YAxis {...createYAxisProps('Energy Consumption (kBTU)')} tickCount={20} interval={0} />
            <Tooltip {...sharedTooltipProps} />
            <Legend {...sharedLegendProps} />
            <Bar yAxisId="left" dataKey="energySumsKBTU.electric" name="Electricity" stackId="a" fill="#4CAF50" />
            <Bar yAxisId="left" dataKey="energySumsKBTU.gas" name="Natural Gas" stackId="a" fill="#FF5252" />
            <Bar yAxisId="left" dataKey="energySumsKBTU.steam" name="Steam" stackId="a" fill="#42A5F5" />
            <Bar yAxisId="left" dataKey="energySumsKBTU.oil" name="Oil" stackId="a" fill="#FFB300" />
            <Bar yAxisId="left" dataKey="energySumsKBTU.water" name="Water" stackId="a" fill="#0277BD" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </CollapsibleChart>
  );
};

export default EnergyUsageBreakdown;
