import { useEffect, useState } from 'react';
import { SidebarOpenIcon } from 'lucide-react';
import { Button } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { useAppLayout } from 'app/utils/AppLayout';
import { AuthUserDropdown } from 'app/shared/widgets/AuthUserDropdown';
import billingServices from 'app/services/billing-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useLocation } from 'react-router-dom';
import projectServices, { ProjectInfoData } from 'app/services/project-services';

export const Header = () => {
  const { sidebarOpen, setSidebarOpen } = useAppLayout();
  const [currentBillPlan, setCurrentBillPlan] = useState({});
  const [currentProject, setCurrentProject] = useState<ProjectInfoData | null>(null);
  const { authUser } = useJumboAuth();
  const location = useLocation();

  useEffect(() => {
    // Extract project ID from URL if it exists
    const projectMatch = location.pathname.match(/\/project\/([^/]+)/);
    const projectId = projectMatch ? projectMatch[1] : null;

    async function fetchProject(id: string) {
      try {
        const project = await projectServices.getById(id);
        setCurrentProject(project);
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    }

    if (projectId) {
      fetchProject(projectId);
    } else {
      setCurrentProject(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    async function fetchBillPlanFromUrl() {
      const billing = await billingServices.getBilling();
      if (!ignore) {
        if (billing.id) {
          setCurrentBillPlan(billing);
        }
      }
    }

    let ignore = false;
    if (authUser) {
      fetchBillPlanFromUrl();
    }

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <header className="sticky mr-6 flex flex-row justify-between">
      {/* <div className="flex items-center gap-4">
        <Button
          className={cn('transition-opacity duration-300 ease-in-out', {
            'pointer-events-none opacity-0': sidebarOpen,
          })}
          size="icon"
          variant="ghost"
          onClick={() => setSidebarOpen((v) => !v)}
        >
          <SidebarOpenIcon className="text-gray-700" />
        </Button>
        <h1 className="text-xl font-semibold text-gray-900">
          {currentProject && (
            `Property: ${currentProject.attributes.name}`
          )}
        </h1>
      </div>
      <AuthUserDropdown /> */}
    </header>
  );
};
