import { useState, forwardRef, useMemo, useEffect } from 'react';
import { ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { Loader2Icon, GaugeIcon, FileTextIcon } from 'lucide-react';
import { UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import { VisualizationsProps, WeatherProps } from 'app/types/visualizations';
import { getTrendData, getTrendDataMeterReadings } from 'app/utils/trendDataHelpers';
import { theme } from 'app/utils/theme';
import { WeatherSection } from 'app/components/weather/WeatherSection';
import { ServiceTabs } from 'app/components/service/ServiceTabs';
import { ServiceContent } from 'app/components/service/ServiceContent';
import { useEnergyStarData } from 'app/hooks/useEnergyStarData';
import type { ProjectDataReturn } from 'app/hooks/useProjectData';
import { NoDataFound } from 'app/components/project/NoDataFound';

interface AnalysisProps extends VisualizationsProps {
  projectData: ProjectDataReturn;
}

export const Analysis = forwardRef<HTMLDivElement, AnalysisProps>(({ projectId, onSnackbar, projectData }, ref) => {
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [baseTemperature, setBaseTemperature] = useState(65);
  const [localWeather, setLocalWeather] = useState<WeatherProps>({ address: '', days: [] });
  const [localZipCode, setLocalZipCode] = useState('');
  const [localStartDate, setLocalStartDate] = useState('');
  const [localEndDate, setLocalEndDate] = useState('');

  const {
    documents,
    meterReadings,
    emissionsFromUtilityBills,
    emissionsFromMeterReadings,
    weather,
    zipCode,
    startDate,
    endDate,
    degreeData,
    degreeDataByBillingPeriod,
    meterReadingDegreeDataByBillingPeriod,
    baseloads,
    meterReadingsBaseloads,
    project,
    isLoading,
    isEmissionsLoading,
    isUpdatingDegreeData,
    error,
  } = projectData;

  // Initialize local state with values from the hook
  useEffect(() => {
    setLocalWeather(weather);
    setLocalZipCode(zipCode);
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [weather, zipCode, startDate, endDate]);

  const allServices = ['All', ...UTILITY_SERVICES];

  const trendDataByService = useMemo(() => {
    const result = {};

    UTILITY_SERVICES.forEach((serviceType) => {
      const serviceBaseload = baseloads[serviceType] || {};

      result[serviceType] = {
        cdd: getTrendData(
          documents,
          serviceBaseload,
          weather,
          degreeData,
          degreeDataByBillingPeriod,
          serviceType,
          'cdd'
        ),
        hdd: getTrendData(
          documents,
          serviceBaseload,
          weather,
          degreeData,
          degreeDataByBillingPeriod,
          serviceType,
          'hdd'
        ),
        meterReadingsCdd: getTrendDataMeterReadings(
          meterReadings || {},
          serviceBaseload,
          weather,
          degreeData,
          meterReadingDegreeDataByBillingPeriod,
          serviceType,
          'cdd'
        ),
        meterReadingsHdd: getTrendDataMeterReadings(
          meterReadings || {},
          serviceBaseload,
          weather,
          degreeData,
          meterReadingDegreeDataByBillingPeriod,
          serviceType,
          'hdd'
        ),
      };
    });
    return result;
  }, [
    documents,
    baseloads,
    weather,
    degreeData,
    degreeDataByBillingPeriod,
    meterReadings,
    meterReadingDegreeDataByBillingPeriod,
  ]);

  const handleSliderChange = (value: number[]) => {
    const newValue = value[0];
    setBaseTemperature(newValue);
  };

  const {
    energyStarScoreByDateRange,
    noScoreReason,
    error: energyStarError,
  } = useEnergyStarData(project?.attributes?.energyStarId || null, meterReadings);

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2Icon className={cn('h-20 w-20 animate-spin text-primary')} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8 text-red-500">
        <NoDataFound
          message="Error loading project data"
          submessage={error.message}
          icon={<Loader2Icon className="h-8 w-8 animate-spin text-muted-foreground" />}
        />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6 text-gray-700">
      <div className="flex h-full w-full flex-col items-center justify-between gap-2 px-4 pb-2">
        <Tabs defaultValue="utility-bills" className="flex h-full w-full flex-col">
          <TabsList className="mb-6 flex h-12 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted p-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.4),0_4px_6px_-1px_rgba(0,0,0,0.3)]">
            <TabsTrigger
              value="utility-bills"
              className="flex-1 items-center justify-center gap-2 rounded-md border border-border/10 bg-background/40 text-gray-700 transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
            >
              <FileTextIcon className="h-4 w-4" />
              Utility Bills
            </TabsTrigger>
            <TabsTrigger
              value="meters"
              className="flex-1 items-center justify-center gap-2 rounded-md border border-border/10 bg-background/40 text-gray-700 transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
            >
              <GaugeIcon className="h-4 w-4" />
              Meters
            </TabsTrigger>
          </TabsList>

          <WeatherSection
            projectId={projectId}
            documents={documents}
            meterReadings={meterReadings}
            weather={localWeather}
            isWeatherOpen={isWeatherOpen}
            isWeatherLoading={isLoading}
            isEditing={isEditing}
            zipCode={localZipCode}
            startDate={localStartDate}
            endDate={localEndDate}
            onWeatherChange={setLocalWeather}
            onWeatherOpenChange={setIsWeatherOpen}
            onEditingChange={setIsEditing}
            onZipCodeChange={setLocalZipCode}
            onStartDateChange={setLocalStartDate}
            onEndDateChange={setLocalEndDate}
            onSnackbar={onSnackbar}
          />

          <ScrollArea className="flex-1 p-4 text-gray-700">
            <TabsContent value="utility-bills" className="mt-0">
              <Tabs defaultValue="all" className="w-full">
                <ServiceTabs allServices={allServices} />
                {allServices.map((serviceType) => (
                  <TabsContent key={serviceType} value={serviceType.toLowerCase()} className="text-gray-700">
                    <ServiceContent
                      serviceType={serviceType}
                      dataSource="utility-bills"
                      documents={documents}
                      meterReadings={meterReadings}
                      trendDataByService={trendDataByService}
                      baseloads={baseloads}
                      meterReadingsBaseloads={meterReadingsBaseloads}
                      baseTemperature={baseTemperature}
                      isUpdatingDegreeData={isUpdatingDegreeData}
                      weather={weather}
                      onBaseTemperatureChange={handleSliderChange}
                      project={project}
                      emissionsFromUtilityBills={emissionsFromUtilityBills}
                      emissionsFromMeterReadings={emissionsFromMeterReadings}
                      isEmissionsLoading={isEmissionsLoading}
                      projectId={projectId}
                      energyStarScoreByDateRange={energyStarScoreByDateRange}
                      noScoreReason={noScoreReason}
                      theme={theme}
                    />
                  </TabsContent>
                ))}
              </Tabs>
            </TabsContent>

            <TabsContent value="meters" className="mt-0">
              <Tabs defaultValue="all" className="w-full">
                <ServiceTabs allServices={allServices} />
                {allServices.map((serviceType) => (
                  <TabsContent key={serviceType} value={serviceType.toLowerCase()} className="text-gray-700">
                    <ServiceContent
                      serviceType={serviceType}
                      dataSource="energy-star"
                      documents={documents}
                      meterReadings={meterReadings}
                      trendDataByService={trendDataByService}
                      baseloads={baseloads}
                      meterReadingsBaseloads={meterReadingsBaseloads}
                      baseTemperature={baseTemperature}
                      isUpdatingDegreeData={isUpdatingDegreeData}
                      weather={weather}
                      onBaseTemperatureChange={handleSliderChange}
                      project={project}
                      emissionsFromUtilityBills={emissionsFromUtilityBills}
                      emissionsFromMeterReadings={emissionsFromMeterReadings}
                      isEmissionsLoading={isEmissionsLoading}
                      projectId={projectId}
                      energyStarScoreByDateRange={energyStarScoreByDateRange}
                      noScoreReason={noScoreReason}
                      theme={theme}
                    />
                  </TabsContent>
                ))}
              </Tabs>
            </TabsContent>
          </ScrollArea>
        </Tabs>
      </div>
    </div>
  );
});
