import { ReactNode } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '@shadcn/utils';
import { useAppLayout } from 'app/utils/AppLayout';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui';
import { LogOutIcon, PanelLeftCloseIcon, PanelLeftOpenIcon } from 'lucide-react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

export type Menu = {
  label: string;
  type: 'section';
  children: {
    uri: string;
    label: string;
    type: 'nav-item';
    icon?: ReactNode;
    onClick?: (e: React.MouseEvent) => void;
  }[];
}[];

const NavItem = ({
  item,
  collapsed,
  className,
}: {
  item: Menu[0]['children'][0];
  collapsed?: boolean;
  className?: string;
}) => {
  const l = useLocation();
  const isActive = l.pathname === item.uri;

  const link = (
    <RouterLink
      to={item.uri}
      onClick={item.onClick}
      className={cn('flex items-center no-underline', {
        'text-primary-foreground': isActive,
        'w-full px-4 py-2': !collapsed,
        'w-full justify-center py-3': collapsed,
      })}
    >
      {item.icon && (
        <div
          className={cn('text-[1.33rem] transition-transform duration-200', {
            'text-primary-foreground': isActive,
            'text-foreground/90': !isActive,
          })}
        >
          {item.icon}
        </div>
      )}
      <span
        className={cn('ml-3 text-sm', {
          'text-primary-foreground': isActive,
          'text-foreground/90': !isActive,
          hidden: collapsed,
        })}
      >
        {item.label}
      </span>
    </RouterLink>
  );

  return (
    <div
      className={cn(
        'block w-full text-foreground/90 hover:text-foreground',
        {
          'bg-primary': isActive,
        },
        className
      )}
    >
      {collapsed ? (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>{link}</TooltipTrigger>
          <TooltipContent side="right" className="ml-2">
            {item.label}
          </TooltipContent>
        </Tooltip>
      ) : (
        link
      )}
    </div>
  );
};

const SectionItem = ({ item }: { item: Menu[0] }) => {
  const { sidebarOpen } = useAppLayout();

  return (
    <div className="w-full">
      <h4
        className={cn(
          'w-full px-4 py-2 text-xs font-medium uppercase text-muted-foreground',
          'transition-all duration-150',
          {
            'translate-x-0 opacity-100': sidebarOpen,
            hidden: !sidebarOpen,
          }
        )}
      >
        {item.label}
      </h4>
      <div className="w-full">
        {item.children.map((child) => {
          return <NavItem key={child.label} item={child} collapsed={!sidebarOpen} />;
        })}
      </div>
    </div>
  );
};

const MenuTree = ({ items }: { items: Menu }) => {
  return (
    <div className="w-full">
      {items.map((item, index) => (
        <SectionItem key={index} item={item} />
      ))}
    </div>
  );
};

export const VerticalNavbar = ({ items = [] }: { items: Menu }) => {
  const { sidebarOpen, toggleSidebar } = useAppLayout();
  const navigate = useNavigate();
  const { setAuthToken } = useJumboAuth();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    setAuthToken(null);
    navigate('/user/login');
  };

  return (
    <div className="flex h-screen flex-col justify-between">
      <div className="mt-6">
        <MenuTree items={items} />
      </div>

      <div
        className={cn('sticky bottom-0 border-t border-border bg-background p-2', 'flex flex-col gap-2', {
          'items-center': !sidebarOpen,
        })}
      >
        {/* Collapse/Expand Button */}
        <NavItem
          item={{
            uri: '#',
            label: sidebarOpen ? 'Collapse' : 'Expand',
            type: 'nav-item',
            icon: sidebarOpen ? <PanelLeftCloseIcon className="h-5 w-5" /> : <PanelLeftOpenIcon className="h-5 w-5" />,
            onClick: (e) => {
              e.preventDefault();
              toggleSidebar();
            },
          }}
          collapsed={!sidebarOpen}
          className="hover:bg-accent hover:text-accent-foreground"
        />

        {/* Logout Button */}
        <NavItem
          item={{
            uri: '#',
            label: 'Logout',
            type: 'nav-item',
            icon: <LogOutIcon className="h-5 w-5" />,
            onClick: handleLogout,
          }}
          collapsed={!sidebarOpen}
          className="hover:bg-accent hover:text-accent-foreground"
        />
      </div>
    </div>
  );
};
