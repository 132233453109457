import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import type { Documents } from 'app/types/visualizations';
import { DataSource } from 'app/types/visualizations';
interface InsightCardsPanelProps {
  serviceType: string;
  documents: Documents;
  meterReadings: any;
  baseloads: any;
  renderLoadMetric: (serviceType: string) => string;
  selectedDataSource: DataSource;
}

const formatValue = (value: number | undefined, isPercentage: boolean = false) => {
  if (!value) return '0.00';

  if (isPercentage) {
    return `${(value * 100).toFixed(2)}%`;
  }
  return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const InsightCardsPanel = ({
  serviceType,
  documents,
  meterReadings,
  baseloads,
  renderLoadMetric,
  selectedDataSource,
}: InsightCardsPanelProps) => {
  const selectedData = selectedDataSource === 'utility-bills' ? documents : meterReadings;
  const serviceData = selectedData?.[serviceType] ?? {};
  const serviceDataByYear = serviceData?.stats?.byYear ?? [];

  return (
    <CollapsibleChart title="Key Insights Summary">
      {serviceData?.stats?.last12Months && (
        <div className="mt-6">
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700">Last 12 Months</h3>
            {serviceData.stats.last12Months.billing_start_date && serviceData.stats.last12Months.billing_end_date && (
              <p className="text-sm text-gray-600">
                {new Date(serviceData.stats.last12Months.billing_start_date).toLocaleDateString()} -{' '}
                {new Date(serviceData.stats.last12Months.billing_end_date).toLocaleDateString()}
              </p>
            )}
          </div>
          <div className="rounded-xl border border-gray-200 bg-white p-4 shadow-lg">
            <div className="divide-y divide-gray-200">
              {/* Energy Usage */}
              <div className="pb-6">
                <h4 className="mb-3 text-sm font-medium text-gray-700">Energy Usage</h4>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <div>
                    <p className="text-sm text-muted-foreground">Total Usage</p>
                    <p className="text-lg font-semibold text-gray-900">
                      {formatValue(serviceData.stats.last12Months.totalUsage)}
                      <span className="text-sm text-muted-foreground"> {renderLoadMetric(serviceType)}</span>
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Total kBtu</p>
                    <p className="text-lg font-semibold text-gray-900">
                      {formatValue(serviceData.stats.last12Months.totalUsageKbtu)}
                    </p>
                  </div>
                  {serviceData.stats.last12Months.siteEUI && (
                    <div>
                      <p className="text-sm text-muted-foreground">Site EUI</p>
                      <p className="text-lg font-semibold text-gray-900">
                        {formatValue(serviceData.stats.last12Months.siteEUI)}
                        <span className="text-sm text-muted-foreground"> kBtu/sqft</span>
                      </p>
                    </div>
                  )}
                  {serviceData.stats.last12Months.sourceEUI && (
                    <div>
                      <p className="text-sm text-muted-foreground">Source EUI</p>
                      <p className="text-lg font-semibold text-gray-900">
                        {formatValue(serviceData.stats.last12Months.sourceEUI)}
                        <span className="text-sm text-muted-foreground"> kBtu/sqft</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* Financial */}
              <div className="pt-6">
                <h4 className="mb-3 text-sm font-medium text-gray-700">Financial</h4>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <div>
                    <p className="text-sm text-muted-foreground">Total Cost</p>
                    <p className="text-lg font-semibold text-gray-900">
                      ${formatValue(serviceData.stats.last12Months.totalCost)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground">Billing Days</p>
                    <p className="text-lg font-semibold text-gray-900">{serviceData.stats.last12Months.billingDays}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {serviceDataByYear.length > 0 && (
        <div className="mt-6">
          <h3 className="mb-4 text-lg font-semibold text-gray-700">Yearly Statistics</h3>
          <div className="space-y-4">
            {serviceDataByYear.map((yearData) => (
              <div key={yearData.year} className="rounded-xl border border-gray-200 bg-white p-4 shadow-lg">
                <h4 className="text-md mb-3 font-medium text-gray-700">{yearData.year}</h4>
                <div className="divide-y divide-gray-200">
                  <div className="pb-6">
                    <h4 className="mb-3 text-sm font-medium text-gray-700">Energy Usage</h4>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                      <div>
                        <p className="text-sm text-muted-foreground">Total Usage</p>
                        <p className="text-lg font-semibold text-gray-900">
                          {formatValue(Number(yearData.totalUsage))}
                          <span className="text-sm text-muted-foreground"> {renderLoadMetric(serviceType)}</span>
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-muted-foreground">Total kBtu</p>
                        <p className="text-lg font-semibold text-gray-900">
                          {formatValue(Number(yearData.totalUsageKbtu))}
                        </p>
                      </div>
                      {yearData.siteEUI && (
                        <div>
                          <p className="text-sm text-muted-foreground">Site EUI</p>
                          <p className="text-lg font-semibold text-gray-900">
                            {formatValue(Number(yearData.siteEUI))}
                            <span className="text-sm text-muted-foreground"> kBtu/sqft</span>
                          </p>
                        </div>
                      )}
                      {yearData.sourceEUI && (
                        <div>
                          <p className="text-sm text-muted-foreground">Source EUI</p>
                          <p className="text-lg font-semibold text-gray-900">
                            {formatValue(Number(yearData.sourceEUI))}
                            <span className="text-sm text-muted-foreground"> kBtu/sqft</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-6">
                    <h4 className="mb-3 text-sm font-medium text-gray-700">Financial</h4>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                      <div>
                        <p className="text-sm text-muted-foreground">Total Cost</p>
                        <p className="text-lg font-semibold text-gray-900">
                          ${formatValue(Number(yearData.totalCost))}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-muted-foreground">Months Covered</p>
                        <p className="text-lg font-semibold text-gray-900">{yearData.monthsCovered}</p>
                      </div>
                      <div>
                        <p className="text-sm text-muted-foreground">Billing Days</p>
                        <p className="text-lg font-semibold text-gray-900">{yearData.billingDays}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </CollapsibleChart>
  );
};
