import { formatNumber } from 'app/utils/formatters';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import { EnergyYearData } from 'app/types/visualizations';
import { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';

interface YearlyUsageByServiceTableProps {
  sortedYears: EnergyYearData[];
  activeServices: {
    electric: boolean;
    gas: boolean;
    steam: boolean;
    oil: boolean;
    water: boolean;
  };
}

const YearlyUsageByServiceTable = ({ sortedYears, activeServices }: YearlyUsageByServiceTableProps) => {
  const [expandedYears, setExpandedYears] = useState<Record<string, boolean>>(() => {
    // Initialize with all years expanded
    return sortedYears.reduce(
      (acc, year) => {
        acc[`${year.startDate}_${year.endDate}`] = true;
        return acc;
      },
      {} as Record<string, boolean>
    );
  });

  const toggleYear = (yearKey: string) => {
    setExpandedYears((prev) => ({
      ...prev,
      [yearKey]: !prev[yearKey],
    }));
  };

  return (
    <CollapsibleChart title="Yearly Usage by Service">
      <div className="overflow-x-auto rounded-lg border border-gray-200">
        <table className="w-full min-w-[700px] border-separate border-spacing-0">
          <thead className="sticky top-0 bg-gray-50">
            <tr>
              <th className="border-b border-gray-200 p-4 text-left text-xs font-medium uppercase tracking-wider text-gray-600">
                UTILITY SERVICE
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                TOTAL USAGE
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                TOTAL KBTU
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                SITE EUI (KBTU/FT²)
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                SOURCE EUI (KBTU/FT²)
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                EMISSIONS (tCO₂)
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                CHARGES ($)
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {sortedYears.map((year) => {
              const yearKey = `${year.startDate}_${year.endDate}`;
              const isExpanded = expandedYears[yearKey];
              return (
                <>
                  {/* Year header row */}
                  <tr key={`${yearKey}-header`} className="bg-gray-100">
                    <td colSpan={7} className="border-b border-gray-200 p-4">
                      <button
                        onClick={() => toggleYear(yearKey)}
                        className="flex w-full items-center text-sm font-medium text-gray-900 hover:text-gray-700"
                      >
                        {isExpanded ? (
                          <ChevronDown className="mr-2 h-4 w-4" />
                        ) : (
                          <ChevronRight className="mr-2 h-4 w-4" />
                        )}
                        {new Date(year.startDate).toLocaleDateString()} - {new Date(year.endDate).toLocaleDateString()}
                      </button>
                    </td>
                  </tr>
                  {isExpanded && (
                    <>
                      {/* Electric row */}
                      {activeServices.electric && (
                        <tr key={`${yearKey}-electric`} className="transition-colors hover:bg-gray-50">
                          <td className="border-b border-gray-200 p-4 text-sm">
                            <div className="text-gray-900">Electric</div>
                            <div className="text-xs text-gray-500">
                              {new Date(year.startDate).toLocaleDateString()} -{' '}
                              {new Date(year.endDate).toLocaleDateString()}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.electric?.totalUsage ?? 0)} kWh
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.electric?.totalKbtu ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.electric?.siteEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.electric?.sourceEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.electric?.emissions ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {year.energyCostsByService?.electric
                                ? formatNumber(year.energyCostsByService.electric)
                                : 'N/A'}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* Gas row */}
                      {activeServices.gas && (
                        <tr key={`${yearKey}-gas`} className="transition-colors hover:bg-gray-50">
                          <td className="border-b border-gray-200 p-4 text-sm">
                            <div className="text-gray-900">Gas</div>
                            <div className="text-xs text-gray-500">
                              {new Date(year.startDate).toLocaleDateString()} -{' '}
                              {new Date(year.endDate).toLocaleDateString()}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.gas?.totalUsage ?? 0)} Therms
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.gas?.totalKbtu ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.gas?.siteEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.gas?.sourceEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.gas?.emissions ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {year.energyCostsByService?.gas ? formatNumber(year.energyCostsByService.gas) : 'N/A'}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* Steam row */}
                      {activeServices.steam && (
                        <tr key={`${yearKey}-steam`} className="transition-colors hover:bg-gray-50">
                          <td className="border-b border-gray-200 p-4 text-sm">
                            <div className="text-gray-900">Steam</div>
                            <div className="text-xs text-gray-500">
                              {new Date(year.startDate).toLocaleDateString()} -{' '}
                              {new Date(year.endDate).toLocaleDateString()}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.steam?.totalUsage ?? 0)} Mlb
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.steam?.totalKbtu ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.steam?.siteEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.steam?.sourceEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.steam?.emissions ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {year.energyCostsByService?.steam ? formatNumber(year.energyCostsByService.steam) : 'N/A'}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* Oil row */}
                      {activeServices.oil && (
                        <tr key={`${yearKey}-oil`} className="transition-colors hover:bg-gray-50">
                          <td className="border-b border-gray-200 p-4 text-sm">
                            <div className="text-gray-900">Oil</div>
                            <div className="text-xs text-gray-500">
                              {new Date(year.startDate).toLocaleDateString()} -{' '}
                              {new Date(year.endDate).toLocaleDateString()}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.oil?.totalUsage ?? 0)} Therms
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.oil?.totalKbtu ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.oil?.siteEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.oil?.sourceEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.oil?.emissions ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {year.energyCostsByService?.oil ? formatNumber(year.energyCostsByService.oil) : 'N/A'}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* Water row */}
                      {activeServices.water && (
                        <tr key={`${yearKey}-water`} className="transition-colors hover:bg-gray-50">
                          <td className="border-b border-gray-200 p-4 text-sm">
                            <div className="text-gray-900">Water</div>
                            <div className="text-xs text-gray-500">
                              {new Date(year.startDate).toLocaleDateString()} -{' '}
                              {new Date(year.endDate).toLocaleDateString()}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.water?.totalUsage ?? 0)} CCF
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.water?.totalKbtu ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.water?.siteEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.water?.sourceEUI ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {formatNumber(year.energyUsageTotalsByService.water?.emissions ?? 0)}
                            </div>
                          </td>
                          <td className="border-b border-gray-200 p-4 text-right">
                            <div className="text-gray-900">
                              {year.energyCostsByService?.water ? formatNumber(year.energyCostsByService.water) : 'N/A'}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* Total row */}
                      <tr key={`${yearKey}-total`} className="bg-gray-50 font-medium">
                        <td className="border-b border-gray-200 p-4 text-sm text-gray-900">Total</td>
                        <td className="border-b border-gray-200 p-4 text-right text-gray-900">-</td>
                        <td className="border-b border-gray-200 p-4 text-right text-gray-900">
                          {formatNumber(
                            (year.energyUsageTotalsByService.electric?.totalKbtu ?? 0) +
                              (year.energyUsageTotalsByService.gas?.totalKbtu ?? 0) +
                              (year.energyUsageTotalsByService.steam?.totalKbtu ?? 0) +
                              (year.energyUsageTotalsByService.oil?.totalKbtu ?? 0) +
                              (year.energyUsageTotalsByService.water?.totalKbtu ?? 0)
                          )}
                        </td>
                        <td className="border-b border-gray-200 p-4 text-right text-gray-900">
                          {formatNumber(year.eui?.siteEUI)}
                        </td>
                        <td className="border-b border-gray-200 p-4 text-right text-gray-900">
                          {formatNumber(year.eui?.sourceEUI)}
                        </td>
                        <td className="border-b border-gray-200 p-4 text-right text-gray-900">
                          {formatNumber(year.actualEmissions)}
                        </td>
                        <td className="border-b border-gray-200 p-4 text-right text-gray-900">
                          {year.totalCost ? formatNumber(year.totalCost) : 'N/A'}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </CollapsibleChart>
  );
};

export default YearlyUsageByServiceTable;
