import { MarkBillBreaks } from 'app/pages/project/tabs/data-sources/document-steps/MarkBillBreaks';
import { EnterDocumentInfo } from 'app/pages/project/tabs/data-sources/document-steps/EnterDocumentInfo';
import { DocumentUpload, FileWithMetadata } from 'app/pages/project/tabs/data-sources/document-steps/DocumentUpload';
import { ReviewUpload } from 'app/pages/project/tabs/data-sources/document-steps/ReviewUpload';
import { useState, useMemo } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@shadcn/ui';
import { Stepper, StepperList, StepperContent, StepLabel } from '@shadcn/custom/Stepper';
import { TriangleAlertIcon, Loader2Icon, CheckCircle2Icon, PlusIcon } from 'lucide-react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.min.mjs';
import { PDFDocument } from 'pdf-lib';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const steps = ['Upload PDFs', 'Enter Document Info', 'Mark Bill Breaks', 'Review & Process'];

interface DocumentStepperProps {
  onDocumentCreate: (files: FileWithMetadata[], billBreaks: Record<string, number[]>) => Promise<any>;
}

interface DocumentToUpload {
  attributes: {
    uploadUrl: string;
    fileName: string;
    pageRange: {
      start: number;
      end: number;
    };
  };
}

export const DocumentStepper = ({ onDocumentCreate }: DocumentStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState<FileWithMetadata[]>([]);
  const [utilityProvider, setUtilityProvider] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [billBreaks, setBillBreaks] = useState({});
  const [error, setError] = useState<null | string>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const filesWithMultipleBills = useMemo(() => files.filter((file) => file.hasMultipleBills), [files]);

  const handleNext = () => {
    if (activeStep === 1) {
      if (filesWithMultipleBills.length > 0) {
        // Go to Mark Bill Breaks
        setActiveStep(2);
        setCurrentFileIndex(files.findIndex((file) => file.fileName === filesWithMultipleBills[0].fileName));
      } else {
        // Skip to Review & Process
        setActiveStep(3);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && filesWithMultipleBills.length === 0) {
      // Go back to Enter Document Info
      setActiveStep(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleMultipleBillsChange = (index: number) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, i) => (i === index ? { ...file, hasMultipleBills: !file.hasMultipleBills } : file))
    );
    if (!files[index].hasMultipleBills) {
      setBillBreaks((prev) => {
        const newBreaks = { ...prev };
        delete newBreaks[files[index].fileName];
        return newBreaks;
      });
    } else if (filesWithMultipleBills.length === 0) {
      setCurrentFileIndex(index);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setBillBreaks((prev) => {
      const newBreaks = { ...prev };
      delete newBreaks[files[index].fileName];
      return newBreaks;
    });
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError('No documents to upload. You must select a document to submit.');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      const documentsToUpload: DocumentToUpload[] = await onDocumentCreate(files, billBreaks);

      const uploadPromises = documentsToUpload.map(async (doc) => {
        const { attributes } = doc;
        const { uploadUrl, fileName, pageRange } = attributes;

        const sourceFile = files.find((f) => {
          const normalizedSourceName = f.fileName.toLowerCase();
          const normalizedTargetName = fileName.replace(/_pages_\d+-\d+\.PDF$/i, '.pdf').toLowerCase();
          return normalizedSourceName === normalizedTargetName;
        });

        if (!sourceFile) {
          throw new Error(
            `Source file not found for ${fileName}. Available files: ${files.map((f) => f.fileName).join(', ')}`
          );
        }

        const bytes = await sourceFile.file.arrayBuffer();
        const sourcePdf = await PDFDocument.load(bytes);
        const newPdf = await PDFDocument.create();

        if (pageRange.start < 1 || pageRange.end > sourcePdf.getPageCount()) {
          throw new Error(
            `Invalid page range ${pageRange.start}-${pageRange.end} for ${fileName}. ` +
              `PDF has ${sourcePdf.getPageCount()} pages`
          );
        }

        const pageIndexes = Array.from(
          { length: pageRange.end - pageRange.start + 1 },
          (_, i) => pageRange.start - 1 + i
        );

        const pages = await newPdf.copyPages(sourcePdf, pageIndexes);
        pages.forEach((page) => newPdf.addPage(page));

        const newPdfBytes = await newPdf.save();
        const fileToUpload = new Blob([newPdfBytes], { type: 'application/pdf' });

        try {
          const response = await axios.put(uploadUrl, fileToUpload, {
            headers: {
              'Content-Type': 'application/pdf',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress((prev) => ({
                ...prev,
                [sourceFile.fileName]: percentCompleted,
              }));
            },
          });

          return response;
        } catch (uploadError) {
          console.error('Upload failed for:', fileName, 'Error:', uploadError);
          throw uploadError;
        }
      });

      await Promise.all(uploadPromises);

      setSuccessMessage('Upload successful!');
    } catch (error) {
      setError(
        `Upload failed: ${error instanceof Error ? error.message : 'An unexpected error occurred during upload.'}`
      );
    } finally {
      setIsUploading(false);
    }
  };

  const resetStepper = () => {
    setActiveStep(0);
    setFiles([]);
    setUtilityProvider('');
    setUploadProgress({});
    setIsUploading(false);
    setCurrentFileIndex(0);
    setBillBreaks({});
    setError(null);
    setSuccessMessage(null);
  };

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          resetStepper();
        }
      }}
    >
      <div className="flex justify-end">
        <DialogTrigger asChild>
          <Button>
            <PlusIcon className="mr-2 h-4 w-4" />
            Add Document
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="h-[90vh] max-h-[1000px] w-full sm:h-[80vh] sm:min-w-fit md:h-[85vh] lg:h-[90vh]">
        <DialogHeader>
          <DialogTitle>
            Upload Documents
            {isUploading && <Loader2Icon className="ml-2 inline h-4 w-4 animate-spin" />}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="grow overflow-hidden">
          <Stepper
            className="flex flex-col overflow-hidden"
            value={`${activeStep}`}
            onValueChange={(v) => setActiveStep(parseInt(v, 10))}
          >
            <StepperList className="mb-6">
              {steps.map((label, index) => {
                return (
                  <StepLabel
                    value={`${index}`}
                    key={label + index}
                    className={`${activeStep === index ? 'text-white' : 'text-muted-foreground'}`}
                  >
                    {label}
                  </StepLabel>
                );
              })}
            </StepperList>
            <StepperContent asChild value="0">
              <DocumentUpload
                files={files}
                setFiles={setFiles}
                handleRemoveFile={handleRemoveFile}
                setError={setError}
              />
            </StepperContent>
            <StepperContent asChild value="1">
              <EnterDocumentInfo
                files={files}
                utilityProvider={utilityProvider}
                setUtilityProvider={setUtilityProvider}
                handleMultipleBillsChange={handleMultipleBillsChange}
              />
            </StepperContent>
            <StepperContent asChild value="2">
              <MarkBillBreaks
                currentFileIndex={currentFileIndex}
                files={files}
                billBreaks={billBreaks}
                setBillBreaks={setBillBreaks}
                filesWithMultipleBills={filesWithMultipleBills}
                setCurrentFileIndex={setCurrentFileIndex}
              />
            </StepperContent>
            <StepperContent asChild value="3">
              <ReviewUpload
                uploadProgress={uploadProgress}
                handleUpload={handleUpload}
                isUploading={isUploading}
                utilityProvider={utilityProvider}
                files={files}
                billBreaks={billBreaks}
              />
            </StepperContent>
          </Stepper>
          {error ? (
            <Alert className="mt-4" variant="destructive">
              <TriangleAlertIcon /> <span>{error}</span>
            </Alert>
          ) : null}

          {successMessage ? (
            <Alert className="mt-4">
              <CheckCircle2Icon className="h-4 w-4 text-green-600" />
              <span className="text-green-600">{successMessage}</span>
            </Alert>
          ) : null}
        </DialogBody>
        <DialogFooter className="">
          <Button variant="secondary" disabled={activeStep === 0 || isUploading} onClick={handleBack}>
            Back
          </Button>
          {activeStep < steps.length - 1 ? (
            <Button onClick={handleNext} disabled={files.length === 0 || isUploading}>
              Next
            </Button>
          ) : (
            <DialogClose asChild>
              <Button variant="secondary" disabled={isUploading}>
                {isUploading ? (
                  <>
                    <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                    Uploading...
                  </>
                ) : (
                  'Cancel'
                )}
              </Button>
            </DialogClose>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
