import { SidebarCloseIcon, SidebarOpenIcon } from 'lucide-react';
import { Button } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import Logo from 'app/shared/Logo';
import { useAppLayout } from 'app/utils/AppLayout';

export const SidebarHeader = () => {
  const { sidebarOpen } = useAppLayout();

  return (
    <div
      className={cn('flex flex-row items-center', {
        'justify-between pl-6 pr-0': sidebarOpen,
        'justify-center': !sidebarOpen,
      })}
    >
      <Logo />
    </div>
  );
};
