import { formatNumber } from 'app/utils/formatters';
import { TrendingDown, TrendingUp } from 'lucide-react';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import type { PropertyMetrics } from 'app/services/energy-star-services';
import { EnergyYearData } from 'app/types/visualizations';

interface YearlyUsageTableProps {
  sortedYears: EnergyYearData[];
  energyStarScoreByDateRange?: { [dateRange: string]: PropertyMetrics };
}

const YearlyUsageTable = ({ sortedYears, energyStarScoreByDateRange }: YearlyUsageTableProps) => {
  return (
    <CollapsibleChart title="Yearly Usage">
      <div className="overflow-x-auto rounded-lg border border-gray-200">
        <table className="w-full min-w-[600px] border-separate border-spacing-0">
          <thead className="sticky top-0 bg-gray-50">
            <tr>
              <th className="min-w-[40%] border-b border-gray-200 p-4 text-left text-xs font-medium uppercase tracking-wider text-gray-600">
                Time Period
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                Site EUI
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                Source EUI
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                Total Emissions (tCO₂)
              </th>
              <th className="border-b border-gray-200 p-4 text-right text-xs font-medium uppercase tracking-wider text-gray-600">
                Energy Star Score
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {sortedYears.map((year, index) => {
              const prevYear = sortedYears[index + 1];
              const emissionsChange = prevYear ? year.actualEmissions - prevYear.actualEmissions : 0;
              const dateRange = `${new Date(year.startDate).toLocaleDateString()} - ${new Date(year.endDate).toLocaleDateString()}`;
              const energyStarScore = energyStarScoreByDateRange?.[dateRange];
              const energyStarScoreValue = energyStarScore?.score;
              return (
                <tr key={`${year.startDate}_${year.endDate}`} className="transition-colors hover:bg-gray-50">
                  <td className="border-b border-gray-200 p-4 text-sm">
                    <div className="text-gray-900">
                      {new Date(year.startDate).toLocaleDateString()} - {new Date(year.endDate).toLocaleDateString()}
                    </div>
                  </td>
                  <td className="border-b border-gray-200 p-4 text-right">
                    <div className="text-gray-900">{formatNumber(year.eui.siteEUI)}</div>
                  </td>
                  <td className="border-b border-gray-200 p-4 text-right">
                    <div className="text-gray-900">{formatNumber(year.eui.sourceEUI)}</div>
                  </td>
                  <td className="border-b border-gray-200 p-4 text-right">
                    <div className="flex items-center justify-end gap-1">
                      <span className="text-gray-900">{formatNumber(year.actualEmissions)}</span>
                      {emissionsChange !== 0 && (
                        <span className={emissionsChange < 0 ? 'text-green-500' : 'text-red-500'}>
                          {emissionsChange < 0 ? (
                            <TrendingDown className="h-4 w-4" />
                          ) : (
                            <TrendingUp className="h-4 w-4" />
                          )}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="border-b border-gray-200 p-4 text-right">
                    <div className="text-gray-900">
                      {energyStarScoreValue ? Math.round(energyStarScoreValue) : 'N/A'}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CollapsibleChart>
  );
};

export default YearlyUsageTable;
