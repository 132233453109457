import { Building2, GitCommit, DollarSign, AlertTriangle, LucideIcon, Gauge } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import { Theme } from 'app/types/theme';
import { formatNumber, formatCurrency } from 'app/utils/formatters';
import { DataSource } from 'app/types/visualizations';
import { ProjectInfoData } from 'app/services/project-services';
import { Documents } from 'app/types/visualizations';
interface InfoCardProps {
  icon: LucideIcon;
  title: string;
  value: string;
  theme: Theme;
  colorIndex?: number;
}

interface EmissionsSummaryProps {
  meterReadings?: Documents;
  utilityBills?: Documents;
  dataSource: DataSource;
  project: ProjectInfoData;
  theme: Theme;
}

const InfoCard = ({ icon: Icon, title, value, theme, colorIndex = 0 }: InfoCardProps) => {
  return (
    <Card className="group relative rounded-xl border-border/10 bg-white/5 backdrop-blur-sm transition-all duration-300 ease-in-out hover:border-primary/20 hover:bg-white/10 hover:shadow-[0_2px_8px_rgba(124,58,237,0.15)]">
      <CardHeader>
        <CardTitle className="flex flex-row items-center gap-2 text-lg">
          <Icon color={theme.text.highlight} className="h-5 w-5 transition-transform group-hover:scale-110" />
          <span className="text-base font-medium text-muted-foreground">{title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent
        className="text-2xl font-bold tracking-tight transition-colors"
        style={{
          color: theme.chart.colors[colorIndex],
        }}
      >
        {value}
      </CardContent>
    </Card>
  );
};

export const EmissionsSummary = ({
  meterReadings,
  utilityBills,
  dataSource,
  project,
  theme,
}: EmissionsSummaryProps) => {
  const data = dataSource === 'utility-bills' ? utilityBills : meterReadings;

  const yearlyData = data?.yearlyData;
  let annualThresholdCO2: number | null = null;
  let estimatedEmissions: number | null = null;
  let estimatedPenalty: number | null = null;
  if (yearlyData) {
    const sortedYears = Object.keys(yearlyData).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    const yearWithFullData = sortedYears.find((year) => {
      const yearData = yearlyData[year];
      return Object.entries(yearData.monthsCoveredByService || {}).some(
        ([service, months]) => service !== 'water' && months === 12
      );
    });

    if (yearWithFullData) {
      const yearData = yearlyData[yearWithFullData];
      annualThresholdCO2 = yearData?.threshold;
      estimatedEmissions = yearData?.actualEmissions;
      estimatedPenalty = yearData?.estimatedAnnualPenalty;
    }
  }
  const squareFeet = project?.attributes?.squareFeet;

  return (
    <div className="mb-8 grid animate-fade-up-in grid-cols-2 gap-6">
      <InfoCard
        icon={Building2}
        title="Building Size"
        value={squareFeet ? `${formatNumber(squareFeet)} sq ft` : '—'}
        theme={theme}
        colorIndex={0}
      />
      <InfoCard
        icon={GitCommit}
        title="Annual CO₂ Emissions Threshold"
        value={annualThresholdCO2 ? `${formatNumber(annualThresholdCO2)} tCO₂` : '—'}
        theme={theme}
        colorIndex={1}
      />
      <InfoCard
        icon={AlertTriangle}
        title="CO₂ Emissions in Last 12 Months"
        value={estimatedEmissions ? `${formatNumber(estimatedEmissions)} tCO₂` : '—'}
        theme={theme}
        colorIndex={2}
      />
      <InfoCard
        icon={DollarSign}
        title="CO₂ Emissions Penalty in Last 12 Months"
        value={estimatedPenalty ? formatCurrency(estimatedPenalty) : '$0'}
        theme={theme}
        colorIndex={3}
      />
    </div>
  );
};
