import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { YearData } from 'app/types/penalty-data';
import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { LineChartIcon } from 'lucide-react';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
} from 'app/components/charts/shared/chartProps';

interface EmissionsTrendAnalysisProps {
  yearsData?: YearData[];
}

const EmissionsTrendAnalysis = ({ yearsData }: EmissionsTrendAnalysisProps) => {
  return (
    <CollapsibleChart title="Emissions Trend">
      {!yearsData?.length ? (
        <NoDataFound
          message="No emissions trend data available"
          submessage="There is no emissions data to display for this time period"
          icon={<LineChartIcon className="h-8 w-8 text-gray-400" />}
        />
      ) : (
        <div className="h-[500px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={yearsData} margin={{ top: 20, right: 80, left: 80, bottom: 100 }}>
              <CartesianGrid {...sharedCartesianGridProps} />
              <XAxis
                {...sharedXAxisProps}
                dataKey="year"
                label={{
                  position: 'bottom',
                  offset: 10,
                }}
              />
              <YAxis {...createYAxisProps('Emissions (tCO2e)')} tickCount={20} interval={0} />
              <Tooltip {...sharedTooltipProps} />
              <Legend {...sharedLegendProps} />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="actualEmissions"
                name="Actual Emissions"
                stroke="#2563eb"
                strokeWidth={2}
                dot={{ fill: '#2563eb', r: 4 }}
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="threshold"
                name="Emissions Threshold"
                stroke="#dc2626"
                strokeWidth={2}
                strokeDasharray="5 5"
                dot={{ fill: '#dc2626', r: 4 }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </CollapsibleChart>
  );
};

export default EmissionsTrendAnalysis;
