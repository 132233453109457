import { NoDataFound } from 'app/components/project/NoDataFound';
import { LineChartIcon } from 'lucide-react';
import { CollapsibleChart } from './CollapsibleChart';
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip as RechartsToolTip,
  Legend,
} from 'recharts';
import { TrendDataPoint } from 'app/types/visualizations';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
  createTemperatureYAxisProps,
} from './shared/chartProps';
import { useState, useMemo } from 'react';
import { Label } from '@shadcn/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/select';

interface UsageTrendChartProps {
  utilityBillsData: TrendDataPoint[];
  meterReadingsData: TrendDataPoint[];
  units: string;
}

interface MeterGroupsResult {
  data: Array<{
    billingPeriod: string;
    [key: string]: any;
  }>;
  meterNames: string[];
}

export const UsageTrendChart = ({ utilityBillsData, meterReadingsData, units }: UsageTrendChartProps) => {
  const [selectedDataSource, setSelectedDataSource] = useState<'utility-bills' | 'energy-star'>(
    utilityBillsData.length > 0 ? 'utility-bills' : 'energy-star'
  );
  const [selectedMeter, setSelectedMeter] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('all');

  // Extract available years from the data
  const availableYears = useMemo(() => {
    const years = new Set<string>();
    const data = selectedDataSource === 'utility-bills' ? utilityBillsData : meterReadingsData;

    data.forEach((item) => {
      const year = new Date(item.billingPeriod.split('_')[0].replace(/-/g, '/')).getFullYear().toString();
      years.add(year);
    });

    return Array.from(years).sort().reverse();
  }, [selectedDataSource, utilityBillsData, meterReadingsData]);

  // Filter data by selected year
  const filterDataByYear = (data: any[]) => {
    if (selectedYear === 'all') return data;

    return data.filter((item) => {
      const itemYear = new Date(item.billingPeriod.split('_')[0].replace(/-/g, '/')).getFullYear().toString();
      return itemYear === selectedYear;
    });
  };

  // Group and process data by source and meter
  const processedData = useMemo(() => {
    const data = selectedDataSource === 'utility-bills' ? utilityBillsData : meterReadingsData;
    if (!data?.length) return { data: [], meterNames: [] } as MeterGroupsResult;

    // Group by meter name
    const meterGroups = new Map<string, any[]>();

    data.forEach((reading) => {
      const meterName = reading.meterName || 'Unknown Meter';
      if (!meterGroups.has(meterName)) {
        meterGroups.set(meterName, []);
      }
      meterGroups.get(meterName)?.push(reading);
    });

    // Get unique meter names
    const meterNames = Array.from(meterGroups.keys());

    // Initialize selected meter if empty
    if (!selectedMeter && meterNames.length > 0) {
      setSelectedMeter(meterNames[0]);
    }

    // Sort data chronologically
    const sortedData = Array.from(meterGroups.entries())
      .reduce((acc, [meterName, readings]) => {
        readings.forEach((reading) => {
          const periodKey = reading.billingPeriod;
          const existingPoint = acc.find((p) => p.billingPeriod === periodKey);

          if (existingPoint) {
            existingPoint[`${meterName}_usage`] = reading.totalUsage;
            if (!existingPoint.temperature && reading.temperature) {
              existingPoint.temperature = reading.temperature;
            }
          } else {
            const newPoint = {
              billingPeriod: periodKey,
              monthYear: reading.monthYear,
              temperature: reading.temperature,
              [`${meterName}_usage`]: reading.totalUsage,
            };
            acc.push(newPoint);
          }
        });
        return acc;
      }, [] as any[])
      .sort((a, b) => {
        const aDate = new Date(a.billingPeriod.split('_')[0]);
        const bDate = new Date(b.billingPeriod.split('_')[0]);
        return aDate.getTime() - bDate.getTime();
      });

    return {
      data: sortedData,
      meterNames,
    };
  }, [selectedDataSource, utilityBillsData, meterReadingsData, selectedMeter]);

  const renderDataSourceSelector = () => {
    if (!utilityBillsData.length && !meterReadingsData.length) return null;

    return (
      <div className="mb-4 flex items-center gap-2">
        <Label className="text-sm font-medium text-muted-foreground">Data Source:</Label>
        <Select
          value={selectedDataSource}
          onValueChange={(value: 'utility-bills' | 'energy-star') => {
            setSelectedDataSource(value);
            setSelectedMeter('');
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select data source" />
          </SelectTrigger>
          <SelectContent>
            {utilityBillsData.length > 0 && <SelectItem value="utility-bills">Utility Bills</SelectItem>}
            {meterReadingsData.length > 0 && <SelectItem value="energy-star">Energy Star</SelectItem>}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderYearFilter = () => {
    if (!availableYears.length) return null;

    return (
      <div className="mb-4 flex items-center gap-2">
        <Label className="text-sm font-medium text-muted-foreground">Filter by Year:</Label>
        <Select value={selectedYear} onValueChange={setSelectedYear}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Years</SelectItem>
            {availableYears.map((year) => (
              <SelectItem key={year} value={year}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderMeterSelector = () => {
    if (!processedData.meterNames.length) return null;

    return (
      <div className="mb-4 flex items-center gap-2">
        <Label className="text-sm font-medium text-muted-foreground">Select Meter:</Label>
        <Select value={selectedMeter} onValueChange={setSelectedMeter}>
          <SelectTrigger className="w-[400px]">
            <SelectValue placeholder="Select meter" />
          </SelectTrigger>
          <SelectContent>
            {processedData.meterNames.map((meterName) => (
              <SelectItem key={meterName} value={meterName}>
                {meterName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderChart = () => {
    const chartData = filterDataByYear(processedData.data);

    if (!chartData || chartData.length === 0) {
      return (
        <NoDataFound
          message="No usage trend data available"
          submessage={
            selectedDataSource === 'energy-star'
              ? 'Energy Star meter readings are not yet available'
              : 'There is no usage trend data to display for this time period'
          }
          icon={<LineChartIcon className="h-8 w-8 text-muted-foreground" />}
        />
      );
    }

    const hasTemperature = chartData.some((point) => point.temperature !== undefined);

    return (
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData} margin={{ top: 20, right: 80, left: 80, bottom: 120 }}>
          <CartesianGrid {...sharedCartesianGridProps} />
          <XAxis
            {...sharedXAxisProps}
            dataKey="monthYear"
            label={{
              value: 'Billing Period',
              position: 'bottom',
              offset: 50,
            }}
            tick={(props) => {
              const { x, y, payload } = props;
              return (
                <g transform={`translate(${x},${y})`}>
                  <text x={0} y={0} dy={10} textAnchor="end" transform="rotate(-45)" fill="#888888" fontSize={12}>
                    {payload.value}
                  </text>
                </g>
              );
            }}
            height={80}
          />
          <YAxis {...createYAxisProps(`Energy Consumption (${units})`)} tickCount={20} interval={0} />
          {hasTemperature && <YAxis {...createTemperatureYAxisProps()} tickCount={15} interval={0} yAxisId="right" />}
          <RechartsToolTip
            {...sharedTooltipProps}
            labelFormatter={(value) => {
              const point = chartData.find((p) => p.monthYear === value);
              return point ? point.billingPeriod.replace('_', ' to ') : value;
            }}
          />
          <Legend {...sharedLegendProps} wrapperStyle={{ paddingTop: 20 }} />
          {selectedMeter && (
            <Line
              key={selectedMeter}
              yAxisId="left"
              unit={` ${units}`}
              name={`${selectedMeter} (${units})`}
              type="monotone"
              connectNulls={true}
              dataKey={`${selectedMeter}_usage`}
              stroke="rgb(var(--chart-energy-rgb))"
              strokeWidth={2}
              dot={{ r: 4 }}
              activeDot={{ r: 8 }}
            />
          )}
          {hasTemperature && (
            <Line
              unit=" °F"
              name="Temperature"
              yAxisId="right"
              type="monotone"
              connectNulls={true}
              dataKey="temperature"
              stroke="rgb(var(--chart-temperature-rgb))"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <CollapsibleChart title={'Usage Trend'}>
      <div className="mb-6 space-y-2">
        {renderDataSourceSelector()}
        {renderYearFilter()}
        {renderMeterSelector()}
      </div>
      {renderChart()}
    </CollapsibleChart>
  );
};
