import { Dispatch, SetStateAction, useState } from 'react';
import { useToast } from '@shadcn/hooks/use-toast';
import {
  Button,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/ui';
import reportServices from 'app/services/report-services';
import type { YearData } from 'app/types/penalty-data';

interface NewReportModalProps {
  projectId: string;
  outputType: 'pdf' | 'xlsx';
  yearsData: YearData[];
  setRefreshTrigger: Dispatch<SetStateAction<number>>;
  handleClose: () => void;
}

export const NewReportModal = ({ projectId, outputType, handleClose, yearsData }: NewReportModalProps) => {
  const [reportName, setReportName] = useState('');
  const [dataSource, setDataSource] = useState<'utility-bills' | 'energy-star'>('utility-bills');
  const { toast } = useToast();
  const [error, setError] = useState<string | null>(null);

  const handleGenerateReport = async () => {
    if (!reportName.trim()) {
      toast({
        description: 'Please enter a report name.',
        variant: 'destructive',
      });
      return;
    }

    try {
      const report = await reportServices.create({
        project: projectId,
        outputType: outputType,
        name: reportName,
        dataSource: dataSource,
      });

      toast({
        description: 'Report created successfully.',
        variant: 'positive',
      });
      handleClose();
      return report;
    } catch (error) {
      console.error('Error generating report', error);

      setError(error instanceof Error ? error.message : 'Error generating report.');
    }
  };

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader className="pb-4">
        <DialogTitle className="text-2xl font-semibold">New Report</DialogTitle>
      </DialogHeader>
      <>
        <div className="grid gap-6 py-6">
          <div className="flex flex-col space-y-2 px-4">
            <Label htmlFor="name" className="text-sm font-medium">
              Name
            </Label>
            <Input
              id="name"
              value={reportName}
              placeholder="Enter report name"
              className="w-full px-4 py-2"
              onChange={(e) => setReportName(e.target.value)}
            />
          </div>
          <div className="flex flex-col space-y-2 px-4">
            <Label htmlFor="dataSource" className="text-sm font-medium">
              Data Source
            </Label>
            <Select value={dataSource} onValueChange={(value: 'utility-bills' | 'energy-star') => setDataSource(value)}>
              <SelectTrigger id="dataSource">
                <SelectValue placeholder="Select data source" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="utility-bills">Utility Bills</SelectItem>
                <SelectItem value="energy-star">Energy Star</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {error && (
          <div className="flex flex-col gap-4 px-4 pb-2">
            <p className="text-sm text-destructive">{error}</p>
          </div>
        )}

        <div className="flex flex-col gap-4 px-4 pb-2">
          <Button className="w-full px-8 py-2 text-base font-medium text-white" onClick={handleGenerateReport}>
            {`Generate Excel Report`}
          </Button>
        </div>
      </>
    </DialogContent>
  );
};
